import React, { useState } from 'react';
import {
  Dialog,
  Pane,
  Heading,
  Table,
  Paragraph,
  SelectMenu,
  Button,
  Position,
  SavedIcon,
  toaster,
} from 'evergreen-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Line, Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { Chart } from 'react-chartjs-2';

// Import and register the necessary chart types
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface RootAccount {
  id: string;
  accountId: string;
  group: string;
  enabled: boolean;
  deviceOs: string;
  coraV: string;
  installed: string | null;
  status: string;
}

interface StatsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  data?: RootAccount[];
}

const StatsDialog: React.FC<StatsDialogProps> = ({
  isOpen,
  onClose,
  data = [],
}) => {
  const [selectedGroup, setSelectedGroup] = useState<string>('All Groups');

  // Initialize startDate and endDate to the last 30 days
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const [startDate, setStartDate] = useState<Date>(thirtyDaysAgo);
  const [endDate, setEndDate] = useState<Date>(today);

  // Quick Date Range Functions
  const setLast30Days = () => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    setStartDate(thirtyDaysAgo);
    setEndDate(today);
  };

  const setLast3Months = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    setStartDate(threeMonthsAgo);
    setEndDate(today);
  };

  const setLastYear = () => {
    const today = new Date();
    const lastYear = new Date();
    lastYear.setFullYear(today.getFullYear() - 1);
    setStartDate(lastYear);
    setEndDate(today);
  };

  // Derive available groups from the data
  const availableGroups = React.useMemo(() => {
    if (!data) return [];
    const groupSet = new Set<string>();
    data.forEach((account) => {
      if (account.group) {
        groupSet.add(account.group);
      }
    });
    return Array.from(groupSet);
  }, [data]);

  // Filter and sort activated accounts
  const activatedAccounts = React.useMemo(() => {
    if (!data || data.length === 0) return [];

    return data
      .filter((account) => {
        const hasInstalled = account.installed && account.installed !== 'N/A';
        const inGroup =
          selectedGroup === 'All Groups' || account.group === selectedGroup;

        if (!hasInstalled) return false;

        const installedDate = new Date(account.installed!);

        // Ensure installedDate is within startDate and endDate
        const inDateRange =
          installedDate >= startDate && installedDate <= endDate;

        return inGroup && inDateRange;
      })
      .sort((a, b) => {
        const dateA = a.installed ? new Date(a.installed).getTime() : 0;
        const dateB = b.installed ? new Date(b.installed).getTime() : 0;
        return dateA - dateB;
      });
  }, [data, selectedGroup, startDate, endDate]);

  // Prepare chart data
  const chartData = React.useMemo(() => {
    if (activatedAccounts.length === 0) return null;

    // Calculate daily activation counts
    const dailyCountsMap: { [date: string]: number } = {};

    activatedAccounts.forEach((account) => {
      const date = new Date(account.installed!).toLocaleDateString();
      dailyCountsMap[date] = (dailyCountsMap[date] || 0) + 1;
    });

    // Get all unique dates and sort them
    const allDates = Object.keys(dailyCountsMap).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    // Prepare cumulative counts
    const cumulativeDataPoints: number[] = [];
    let cumulativeCount = 0;

    // Prepare daily counts
    const dailyDataPoints: number[] = [];

    allDates.forEach((date) => {
      const dailyCount = dailyCountsMap[date] || 0;
      cumulativeCount += dailyCount;
      dailyDataPoints.push(dailyCount);
      cumulativeDataPoints.push(cumulativeCount);
    });

    return {
      labels: allDates,
      datasets: [
        {
          type: 'line',
          label: 'Cumulative Activations',
          data: cumulativeDataPoints,
          fill: false,
          borderColor: 'rgb()',
          tension: 0.1,
          yAxisID: 'y1',
        },
        {
          type: 'bar',
          label: 'Daily Activations',
          data: dailyDataPoints,
          borderColor: 'rgba(76, 184, 72, 1)',
          backgroundColor: 'rgba(76, 184, 72, 0.2)',
          yAxisID: 'y2',
        },
      ],
    } as ChartData<'line'>;
  }, [activatedAccounts]);

  const chartOptions: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
            display: true,
        },
        ticks: {
            autoSkip: false,
            maxTicksLimit: 10,
        }
      },
      y1: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Cumulative Activations',
        },
        grid: {
            display: true,
        },
        ticks: {
            precision: 0,
        },
      },
      y2: {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Daily Activations',
        },
        grid: {
          drawOnChartArea: false, // Prevent grid lines from y2 overlapping with y1
        },
        ticks: {
            precision: 0,
        },
      },
    },
  };

  const handleDownloadCSV = () => {
    if (activatedAccounts.length === 0) {
      toaster.warning('No data to download');
      return;
    }

    // Prepare CSV content
    const headers = ['Account ID', 'Group', 'Activation Date'];
    const rows = activatedAccounts.map((account) => [
      account.accountId,
      account.group,
      account.installed
        ? new Date(account.installed).toLocaleString()
        : 'N/A',
    ]);

    const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', 'activated_users.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog
      isShown={isOpen}
      title="Activation Statistics"
      onCloseComplete={onClose}
      confirmLabel="Close"
      width={1200}
      hasFooter={false}
    >
      <Pane>
        {/* Filters and Actions */}
        <Pane display="flex" alignItems="center" marginBottom={16} gap={16}>
          {/* Group Filter */}
          <SelectMenu
            title="Select Group"
            options={['All Groups', ...availableGroups].map((group) => ({
              label: group,
              value: group,
            }))}
            selected={selectedGroup}
            onSelect={(item) => setSelectedGroup(item.value.toString())}
            position={Position.BOTTOM_LEFT}
          >
            <Button>{selectedGroup} ▼</Button>
          </SelectMenu>

          {/* Date Range Picker */}
          <Pane display="flex" alignItems="center" gap={8}>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => setStartDate(date!)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
            />
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => setEndDate(date!)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
            />
          </Pane>

          {/* Quick Date Range Buttons */}
          <Pane display="flex" alignItems="center" gap={8}>
            <Button onClick={setLast30Days}>Last 30 Days</Button>
            <Button onClick={setLast3Months}>Last 3 Months</Button>
            <Button onClick={setLastYear}>Last Year</Button>
          </Pane>

          {/* Spacer */}
          <Pane flex={1} />

          {/* Download CSV Button */}
          <Button
            appearance="primary"
            iconBefore={SavedIcon}
            onClick={handleDownloadCSV}
          >
            Download CSV
          </Button>
        </Pane>

        {chartData ? (
          <>
            <Heading size={600} marginBottom={16}>
              Activation Over Time
            </Heading>
            <Line data={chartData} options={chartOptions} />
          </>
        ) : (
          <Paragraph>No activation data available.</Paragraph>
        )}

        {activatedAccounts.length > 0 && (
          <>
            <Heading size={600} marginTop={32} marginBottom={16}>
              Activated Users
            </Heading>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell>Account ID</Table.TextHeaderCell>
                <Table.TextHeaderCell>Group</Table.TextHeaderCell>
                <Table.TextHeaderCell>Activation Date</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {activatedAccounts.map((account) => (
                  <Table.Row key={account.accountId}>
                    <Table.TextCell>{account.accountId}</Table.TextCell>
                    <Table.TextCell>{account.group}</Table.TextCell>
                    <Table.TextCell>
                      {account.installed
                        ? new Date(account.installed).toLocaleString()
                        : 'N/A'}
                    </Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        )}
      </Pane>
    </Dialog>
  );
};

export default StatsDialog;
